define("discourse/plugins/discourse-fingerprint/initializers/fingerprint", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/load-script"], function (_exports, _runloop, _ajax, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "fingerprint",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const user = container.lookup("service:current-user");
      if (!siteSettings.fingerprint_enabled || !user) {
        return;
      }

      // Wait for 3 seconds before fingerprinting user to let the browser use
      // resources for more important tasks (i.e. resource loading, rendering).
      (0, _runloop.later)(() => {
        (0, _loadScript.default)("/plugins/discourse-fingerprint/javascripts/fp.js").then(() => {
          /* global FingerprintJS */
          FingerprintJS.load().then(fp => fp.get()).then(result => {
            const resultMap = {};
            Object.keys(result.components).forEach(key => resultMap[key] = result.components[key].value);
            (0, _ajax.ajax)("/fingerprint", {
              type: "POST",
              data: {
                visitor_id: result.visitorId,
                version: result.version,
                data: JSON.stringify(resultMap)
              }
            });
          });
        });
      }, 3000);
    }
  };
});