define("discourse/plugins/discourse-fingerprint/discourse/routes/admin-plugins-fingerprint", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    controllerName: "fingerprintReport",
    templateName: "fingerprintReport",
    setupController(controller) {
      controller.update(controller.username);
    }
  });
});